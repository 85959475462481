@import "~@/erp/styles/variables/variables.scss";











































































































































































































.tips {
  .el-tooltip {
    font-size: 18px;
    cursor: pointer;
    vertical-align: top;
  }
}
