@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-input-group__prepend {
    background-color: #fff;
    padding: 0 20px !important;
    .el-select {
      width: 80px;
      .el-input__inner {
        padding-left: 5px;
      }
    }
  }
}
.mail-record {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 177px);
  .tool-bar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 15px 0;
    border-bottom: 1px solid #f5f7fa;
    > .el-select {
      margin-right: 10px;
    }
  }

  .mail-content-wrapper {
    flex: 1;
    height: 500px;
    display: flex;
    .aside {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #f5f7fa;
    }
    .mail-info-wrapper {
      flex: 1;
    }
  }
}
.mail-list {
  height: 500px;
  flex: 1;
  overflow-y: auto;
  .no-data {
    text-align: center;
    color: #999;
    line-height: 40px;
  }
  .item {
    border-bottom: 1px solid #f5f7fa;
    padding: 6px 4px;
    background-color: #fff;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #f8f8f8;
    }
  }
  .item-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .mark {
      font-size: 12px;
      color: #fff;
      line-height: 16px;
      padding: 1px 3px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;
      &.receive {
        background-color: #71aaff;
      }
      &.send {
        background-color: #facd91;
      }
    }
    .name {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      flex: 1;
      .icon-qizhi {
        color: #0486fe;
        margin-left: 5px;
      }
    }
    .time {
      font-size: 12px;
      color: #999;
      line-height: 22px;
    }
  }
  .item-body {
    display: flex;
    .dt {
      min-width: 30px;
      margin-right: 10px;
      text-align: right;
      i {
        color: #f7b500;
        &.mail-icon {
          display: block;
        }
        &.looked {
          color: #d9d9d9;
        }
        &.replyed {
          color: #2eb797;
        }
        &.send {
          color: #3abc38;
        }
      }
    }
    .dd {
      flex: 1;
      width: 200px;
      .file {
        color: #fa6400;
      }
    }
    .summary {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      font-size: 14px;
      color: #999;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .label-list {
      overflow: hidden;
      li {
        float: left;
        margin-right: 10px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        .label {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
        }
        &:hover {
          .remove-icon {
            display: block;
          }
        }
      }
    }
  }
}

.search-pagination {
  height: 40px;
  background: #fff;
  border-top: 1px solid #f5f7fa;
  display: flex;
  align-items: center;
  padding: 0 20px;
  ::v-deep .el-select {
    width: 60px;
    margin-left: 2px;
    .el-input__inner {
      padding: 0 4px;
    }
    .el-input__suffix {
      display: none;
    }
  }
  .page-current {
    display: flex;
    width: 60px;
    flex: 1;
    align-items: center;
    justify-content: center;
    .page-num {
      padding: 0 10px;
    }
  }
  .jump {
    ::v-deep .el-input {
      width: 32px;
      margin-left: 2px;
      margin-right: 2px;
      .el-input__inner {
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
      }
    }
  }
}
.mail-detail {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  //border: 1px solid red;
  overflow: auto;
  .mail-detail-base {
    border-bottom: 1px solid #f5f7fa;
    padding: 20px;
    box-sizing: border-box;
    .title-box {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      margin-bottom: 10px;
      .mail-main-title {
        // display: flex;
        // align-items: flex-start;
        // flex: 1;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        max-width: 150em;
        word-break: break-all;
      }
      .order-num {
        padding: 0 3px;
        white-space: nowrap;
        color: #1890ff;
        background: #e8f4ff;
        font-size: 14px;
        margin-left: 10px;
        .icon-close {
          color: #ccc;
        }
      }
      .label-item {
        display: inline-block;
        margin-left: 10px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        line-height: 20px;
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          vertical-align: middle;
        }
        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
          vertical-align: middle;
        }
        &:hover {
          .remove-icon {
            display: inline-block;
          }
        }
      }
    }
    .operate-btns {
      margin-left: 20px;
      .el-button {
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 18px;
      }
    }
    .info-wrapper {
      display: flex;
      .info-main {
        flex: 1;
        width: 200px;
      }
      .info-simple {
        font-size: 14px;
        color: #333;
        line-height: 24px;
        word-break: break-all;
        .desc {
          color: #999;
        }
      }
      .btn-key {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        cursor: pointer;
        &:hover {
          color: #1890ff;
        }
      }
    }
    .base-item {
      display: flex;
      margin-bottom: 10px;
      line-height: 24px;
      dt {
        width: 82px;
        height: 24px;
        background: #f8fbff;
        border-radius: 3px;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
      }
      dd {
        flex: 1;
        width: 0;
        word-wrap: break-word;
        font-size: 12px;
        color: #777;
        .weight {
          color: #303030;
        }
        .star {
          color: #fa6400;
        }
      }
    }
    .myremark {
      background-color: #f8f8f8;
      padding: 4px 6px;
      margin-top: 6px;
      .remark-text {
        display: flex;
        align-items: flex-end;
        .weight {
          font-size: 14px;
          line-height: 28px;
          word-wrap: break-word;
          flex: 1;
          width: 200px;
        }
      }
      .remark-form {
        display: flex;
        align-items: center;
        .input {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
  }
  .mail-frame {
    flex: 1;
    height: 500px;
  }
  .mail-detail-attach {
    padding: 20px;
    box-sizing: border-box;
    .attach-title {
      margin-bottom: 10px;
      i {
        color: #fa6400;
        font-size: 12px;
      }
      & > span {
        font-size: 12px;
        font-weight: bold;
        color: #303030;
        margin: 0 10px;
      }
    }
    .attach-list {
      display: flex;
      flex-wrap: wrap;
      & > div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #f5f7fa;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;
        i {
          color: #fa6400;
          font-size: 12px;
        }
        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin-left: 10px;
        }
        .preview {
          color: #1890ff;
          margin-left: 10px;
        }
        .size {
          color: #777;
          margin-left: 10px;
        }
      }
    }
  }
  .mail-deatil-bottom {
    overflow-y: auto;
    max-height: 120px;
  }
}
.no-mail {
  width: 100%;
  height: 100%;
  background: #fff;
  border-left: 1px solid #f5f7fa;
  box-sizing: border-box;
  position: relative;
  & > div {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100px;
      height: 100px;
    }
    p {
      margin-top: 33px;
      color: #777;
      font-size: 14px;
    }
  }
}
