@import "~@/erp/styles/variables/variables.scss";























































































































































































































































































































































































































































































.dialog-content {
  height: 500px;

  .el-form {
    width: 95%;
    margin: 0 auto;
  }

  .tree-box {
    width: 100%;
    height: calc(100% - 62px);
    border: 1px solid #ededed;
    padding: 20px;

    .el-col {
      height: 100%;
    }
  }
}

.span-tip {
  font-weight: normal;
}

.check-user {
  border-bottom: 1px solid #ededed;
  padding-bottom: 15px;
  margin-top: 15px;

  p {
    margin-left: 10px;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }

  .el-tree-node__content {
    height: 35px;
    line-height: 35px;
  }
}

.delete-all {
  line-height: 21px;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background-color: #606266;
}

.single-select-tip {
  height: 32px;
  line-height: 32px;
  padding-left: 8px;
  font-size: 14px;
}
